import React, {useEffect, useState} from 'react';
import {InputAdornment, MenuItem, Select, TableCell, TableRow, Typography} from '@mui/material';

import {TextValidator} from 'react-material-ui-form-validator';
import Autocomplete from '@mui/material/Autocomplete';
import {ReactComponent as DeleteIcon} from '../../../../assets/images/icons/delete-icon.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const BookingRateForm = (props) => {
    const {
        onRatesValueChange,
        item,
        remove,
        tasks,
        hoursList,
        otRateList,
        taxList,
        isInitialRequestedRates,
        requestRates,
    } = props;

    const [endDate, setEndDate] = useState(new Date());
    const [keyDateObj, setKeyDateObj] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [updatedTaskType, setUpdatedTaskType] = useState('');
    const [kd, setKd] = useState('');
    let otRate = item.otRate;

    useEffect(() => {
        setKeyDateObj(item);
    }, [item]);

    const onFieldChange = (event, name, value) => {
        console.log('event---->', event);
        if (!value) {
            value = event.target.value;
        }
        //  event.persist();
        if (name === 'per') {
            otRate = item.rate ? calculateOTRate(item.rate, item.otRate, value.value) : otRate;
            onRatesValueChange({ ...keyDateObj, [name]: value, otRate }, props.index);
        } else if (name === 'rate') {
            if (isNaN(value)) {
                onRatesValueChange({ ...keyDateObj, [name]: '' }, props.index);
            } else {
                otRate = calculateOTRate(value, item.otRate, item.per.value);
                onRatesValueChange({ ...keyDateObj, [name]: value, otRate }, props.index);
            }
        } else if (name === 'otRate') {
            otRate = item.rate ? calculateOTRate(item.rate, value, item.per.value) : { value: 0, label: '(@1.5x)' };
            onRatesValueChange({ ...keyDateObj, [name]: otRate }, props.index);
        } else {
            onRatesValueChange({ ...keyDateObj, [name]: value }, props.index);
        }
    };

    const calculateOTRate = (rate, otRate, per) => {
        return {
            value: !per ? rate * 1.5 : (rate / (per || 1)) * otRate.label.replace(/[(@x)]/g, ''),
            label: otRate.label,
        };
    };

    const handleEffectiveDateChange = (event, values) => {
        event.persist();
        setKd(values);

        onRatesValueChange({ ...keyDateObj, taskType: values }, props.index);
    };
    console.log('REQUEST RATES', requestRates);
    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography variant="overline" component="div" sx={{ mb: '0.429rem' }}>
                        TASK TYPE
                    </Typography>
                    {isInitialRequestedRates || requestRates ? (
                        <Autocomplete
                            required
                            size="small"
                            popupIcon={<KeyboardArrowDownIcon color="secondary" />}
                            onChange={handleEffectiveDateChange}
                            disableClearable
                            value={item.taskType ? item.taskType : updatedTaskType}
                            options={tasks}
                            autoHighlight
                            getOptionLabel={(option) => option.title || ''}
                            sx={{ minWidth: '110px' }}
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label=""
                                    placeholder={item.taskType ? '' : 'Choose'}
                                    fullWidth
                                    variant="standard"
                                    value={item.taskType}
                                    validators={['required']}
                                    errorMessages={['Task type is required.']}
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                />
                            )}
                            componentsProps={{
                                paper: {
                                    sx: {
                                        minWidth: '200px',
                                        width: '100%',
                                    },
                                },
                            }}
                        />
                    ) : (
                        item && item.taskType && <Typography variant="body2">{item.taskType.title}</Typography>
                    )}
                </TableCell>
                <TableCell>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        Rate
                    </Typography>
                    {isInitialRequestedRates || requestRates ? (
                        <TextValidator
                            onChange={(event) => onFieldChange(event, 'rate', event.target.value)}
                            fullWidth
                            value={item.rate}
                            name="rate"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            style={{ marginTop: '10px' }}
                            validators={['required']}
                            errorMessages={['Rate is required.']}
                        />
                    ) : (
                        item &&
                        item.rate && (
                            <Typography variant="h1" sx={{ fontSize: '1.714rem', lineHeight: '1.44rem' }}>
                                <Typography variant="small" component="sup">
                                    $
                                </Typography>
                                {item.rate}
                            </Typography>
                        )
                    )}
                </TableCell>
                <TableCell>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        Per
                    </Typography>
                    {isInitialRequestedRates || requestRates ? (
                        <Autocomplete
                            required
                            size="small"
                            popupIcon={<KeyboardArrowDownIcon color="secondary" />}
                            onChange={(event, newValue) => onFieldChange(event, 'per', newValue)}
                            disableClearable
                            value={item.per ? item.per : ''}
                            options={hoursList}
                            autoHighlight
                            getOptionLabel={(option) => option.label || ''}
                            style={{ minWidth: '85px' }}
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label=""
                                    placeholder={item.per ? '' : 'Choose'}
                                    fullWidth
                                    variant="standard"
                                    value={item.per}
                                    validators={['required']}
                                    errorMessages={['Per is required.']}
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                />
                            )}
                            componentsProps={{
                                paper: {
                                    sx: {
                                        minWidth: '100px',
                                        width: '100%',
                                    },
                                },
                            }}
                        />
                    ) : (
                        item && item.per && <Typography variant="body2">{item.per.label || 'Hour'}</Typography>
                    )}
                </TableCell>

                <TableCell sx={{ textAlign: 'right', paddingRight: '10px!important', paddingLeft: '0px!important' }}>
                    <DeleteIcon
                        className="pointer"
                        onClick={() =>
                            !isInitialRequestedRates ? 'javascript:;' : remove(item.id ? item.id : item._id)
                        }
                    />
                </TableCell>
            </TableRow>
            <TableRow
                sx={{
                    borderBottom: '1px solid #EEEEEE',
                    '&:last-of-type': {
                        borderBottom: 'none',
                    },
                }}
            >
                <TableCell>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        OT P/H Rate
                    </Typography>
                    {isInitialRequestedRates || requestRates ? (
                        <Select
                            IconComponent={KeyboardArrowDownIcon}
                            fullWidth
                            onChange={(event) => onFieldChange(event, 'otRate')}
                            name="otRate"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            //style={{ width: '95px' }}
                            variant="standard"
                            displayEmpty
                            defaultValue=""
                            value={
                                item.calculatedOTRate && item.calculatedOTRate.value == 'NA'
                                    ? item.calculatedOTRate
                                    : item.otRate
                            }
                            renderValue={(selected) => {
                                if (selected) {
                                    return selected.value !== 'NA'
                                        ? `$ ${selected.value}${selected.label}`
                                        : `$ ${selected.value}`;
                                } else {
                                    return <div>Choose</div>;
                                }
                            }}
                            options={otRateList}
                            inputProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                },
                            }}
                            disableUnderline
                        >
                            {otRateList &&
                                otRateList.map((eachValue, index) => (
                                    <MenuItem key={eachValue.value + index} value={eachValue}>
                                        {eachValue.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    ) : (
                        item &&
                        item.otRate && (
                            <Typography variant="body2">
                                $ {item.otRate.value}
                                {item.otRate.label}
                            </Typography>
                        )
                    )}
                </TableCell>
                <TableCell>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        Tax
                    </Typography>
                    {isInitialRequestedRates || requestRates ? (
                        <Autocomplete
                            required
                            size="small"
                            popupIcon={<KeyboardArrowDownIcon color="secondary" />}
                            onChange={(event, value) => onFieldChange(event, 'tax', value)}
                            disableClearable
                            value={item.tax ? item.tax : ''}
                            options={taxList}
                            autoHighlight
                            getOptionLabel={(option) => option.label || ''}
                            sx={{ minWidth: '80px' }}
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label=""
                                    placeholder={item.tax ? '' : 'Choose'}
                                    fullWidth
                                    variant="standard"
                                    value={item.tax}
                                    validators={['required']}
                                    errorMessages={['Tax is required.']}
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                />
                            )}
                        />
                    ) : (
                        item && item.tax && <Typography variant="body2">{item.tax.label}</Typography>
                    )}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        </>
    );
};

export default BookingRateForm;
