/* Route declarations for the app */

import PrivateRoutes from './PrivateRoutes';
import * as reviews from '../views';
import {AUTH_ROUTES} from "../constants/SiteRoutes";

const routeData = [
    {
        id: 'route-001',
        path: '/login',
        component: reviews.Login,
        requiresAuth: false,
    },
    {
        id: 'route-000',
        path: '*',
        component: reviews.NoPageFound,
        requiresAuth: false,
    },
    {
        id: 'route-003',
        path: '/signup',
        component: reviews.SignUp,
        requiresAuth: false,
    },
    {
        id: 'route-004',
        path: '/account-choice',
        component: reviews.AccountChoice,
        requiresAuth: false,
    },
    {
        id: 'route-005',
        path: '/login-one-time',
        component: reviews.LoginOneTime,
        requiresAuth: false,
    },
    {
        id: 'route-006',
        path: '/home',
        component: reviews.Home,
        requiresAuth: false,
    },
    {
        id: 'route-008',
        path: AUTH_ROUTES.RESEND_VERIFICATION_EMAIL,
        component: reviews.ResendVerificationEmail,
        requiresAuth: false,
    },
    {
        id: 'route-009',
        path: `${AUTH_ROUTES.VERIFY_EMAIL}/:code`,
        component: reviews.VerifyEmail,
        requiresAuth: false,
    },
    {
        id: 'route-010',
        path: AUTH_ROUTES.VERIFY_PHONE_NUMBER,
        component: reviews.PhoneNumberVerification,
        requiresAuth: false,
    },
    {
        id: 'route-011',
        path: AUTH_ROUTES.FORGOT_PASSWORD,
        component: reviews.ForgotPassword,
        requiresAuth: false,
    },
    {
        id: 'route-012',
        path: `${AUTH_ROUTES.RESET_PASSWORD}/:code`,
        component: reviews.ResetPassword,
        requiresAuth: false,
    },
    {
        id: 'route-013',
        path: AUTH_ROUTES.RESET_PASSWORD_SUCCESS,
        component: reviews.ResetPasswordSuccess,
        requiresAuth: false,
    },
    { id: 'route-007', path: '/', component: reviews.Home, requiresAuth: false },
    {
        id: 'route-002',
        path: '/',
        component: PrivateRoutes,
        children: [
            { path: 'bookings', component: reviews.BookingList },
            { path: 'mycalendar', component: reviews.Calendar },
            { path: 'booking-details/:bookingId', component: reviews.BookingDetails },
        ],
        requiresAuth: true,
    },
];

export default routeData;
