import React, { useEffect, useState } from 'react';

import { Box, Button, FormControl, MenuItem, Select, Stack, Typography, useTheme, Popper } from '@mui/material';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextValidator } from 'react-material-ui-form-validator';
import PhoneField from 'src/components/Common/PhoneNumber';
import { makeStyles } from '@mui/styles';
import { ReactComponent as DeleteIcon } from 'src/assets/images/icons/delete-icon.svg';
import { BOOKING_REQUEST } from 'src/constants/ApiEndPoint';
import { fetchRecord } from 'src/services/Axios';
import { Avatar } from '@material-ui/core';
import Configs from 'src/configs/Configs';

const useStyles = makeStyles((theme) => ({
    // rowItem: {
    //     fontSize: '14px',
    //     lineHeight: '24px',
    //     display: 'flex',
    //     alignItems: 'center',
    //     color: '#141414',
    //     overflow: 'hidden',
    // },
    // rowItemAvatar: {
    //     whiteSpace: 'nowrap',
    //     fontSize: '14px',
    //     lineHeight: '24px',
    //     display: 'flex',
    //     alignItems: 'center',
    //     color: '#141414',
    //     overflow: 'hidden',
    // },
    // roleItem: {
    //     fontSize: '14px',
    //     lineHeight: '24px',
    //     alignItems: 'center',
    //     color: '#141414',
    //     overflow: 'hidden',
    //     whiteSpace: 'nowrap',
    //     width: '106px',
    //     textOverflow: 'ellipsis',
    // },
    // phoneInput: {
    //     '& .MuiInput-root .Mui-disabled': {
    //         color: '#141414',
    //     },
    //     left: '-17px',
    // },
    phoneDiv: {
        '& .react-tel-input': {
            padding: '3px 0 0',
            '& .flag-dropdown ': {
                left: '1px',
                top: '9px',
                '& .selected-flag': {
                    height: '16px',
                },
            },
            '& .MuiInput-root': {
                '&::before': {
                    borderBottom: '1px solid #BFEADE',
                },
                '&.Mui-disabled': {
                    '&::before': {
                        borderBottom: 'none',
                    },
                },
            },
            '& input[type=tel]': {
                padding: '0 0 4px 32px!important',
                height: '26px',
            },
        },
    },
    nakedInput: {
        '& .MuiInput-input': {
            padding: '7px 0',
            '&:focus': {
                background: 'transparent',
            },
        },
        '&:before': {
            borderColor: '#BFEADE',
        },
        // '&&:after': {
        //     borderBottom: 'none',
        // },
        // '& .MuiInput-underline:after': {
        //     borderBottom: 'none',
        //     '&&&:before': {
        //         borderBottom: 'none',
        //     },
        //     '&&:after': {
        //         borderBottom: 'none',
        //     },
        // },
    },
    autoCompleteInput: {
        '& .MuiTextField-root': {
            '& .MuiInputBase-root': {
                borderBottom: '1px solid #BFEADE',
                '&:before': {
                    borderBottom: 'none',
                },
            },
        },
    },
    stdInput: {
        '& .MuiInput-root': {
            '&::before': {
                borderBottom: '1px solid #BFEADE',
            },
            '&.Mui-disabled': {
                '&::before': {
                    borderBottom: 'none',
                },
            },
        },
    },
    popper: {
        width: 'fit-content',
    },
    // avatar: {
    //     width: 27,
    //     height: 27,
    // },
    // preferredCrewContainer: {},
}));

function PreferredCrewItem(props) {
    const {
        roles,
        item,
        key,
        index,
        myContacts,
        setCrews,
        deletePreferredCrew,
        isDisabled,
        setRoles,
        preferences,
        setMyContacts,
        setIsChanged,
        crews,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    console.log(item, 'item');
    const [role, setRole] = useState(item.role);
    const [crew, setCrew] = useState(item);
    const [newCrew, setNewCrew] = useState(true);
    const [number, setNumber] = useState(item.preferredNumber || 1);
    const [email, setEmail] = useState(item.email);
    const [phoneNumber, setPhoneNumber] = useState(item.phoneNumber);
    const [contactId, setContactId] = useState(item.contactId);
    const [loading, setLoading] = useState(item.contactId);
    const filter = createFilterOptions();

    useEffect(() => {
        setEmail(item.email);
        setRole(item.role);
        setNumber(item.preferredNumber || 1);
        setPhoneNumber(item.phoneNumber);
        setCrew({
            _id: item._id,
            fullName: item.fullName || '',
            photoUri: item.photoUri || '',
        });
    }, [item]);

    const handleCrewChange = (event, value, reason) => {
        console.log('handleCrewChange====>', value);
        console.log('handleCrewChange====>', index);
        setCrew(value);
        if (value && value._id && value.userType) {
            setNewCrew(false);
            setEmail(value.email);
            setPhoneNumber(value.phoneNumber);
            setContactId(value._id);
        } else {
            setNewCrew(true);
            setEmail('');
            setPhoneNumber('');
            setContactId(null);
        }

        setCrews((prevValues) => {
            const newValues = [...prevValues];
            const crewRow = {
                _id: value._id || new Date().getTime(),
                contactId: value.inCrews ? null : value._id,
                role: role,
                crew: value,
                email: value && value.email ? value.email : '',
                preferredNumber: number,
                phoneNumber: value && value.phoneNumber,
                fullName: value && value.fullName,
                photoUri: value && value.photoUri,
                isAddedByBooker: value.isAddedByBooker,
                inCrews: value.inCrews,
            };
            console.log('IIIIII----->', index);
            console.log('IIIIII----->', newValues);
            if (index != undefined) {
                newValues[index] = crewRow;
            } else {
                newValues[crews.length - 1] = crewRow;
            }
            console.log('IIIIII----->', newValues);
            return newValues;
        });
        setIsChanged(true);
    };

    const handleRoleInputChange = async (event, value, reason) => {
        // if (reason === 'input') {
        //     let response = await fetchRecord(COMPONENTS.ROLES + '?search=' + value);
        //     if (response.status === COMMON_CODES.SUCCESS) {
        //         const allResponse = response.data;
        //         if (allResponse) {
        //             setRoles(allResponse);
        //         }
        //     }
        // }
    };

    const handleCrewInputChange = async (event, value, reason) => {
        console.log('value==>', value);
        // if (reason === 'input') {
        //     let response = await fetchRecord(COMPONENTS.CONTACTS + '?page=1&perPageLimit=10&searchText=' + value);
        //     if (response.status === COMMON_CODES.SUCCESS) {
        //         const allResponse = response.data.docs;
        //         if (allResponse) {
        //             setMyContacts(allResponse);
        //         }
        //     }
        // }
    };

    const handleInputChange = (name, value, reason) => {
        console.log('changed=======>', index);
        console.log('changed=======>', name);
        console.log('changed=======>', value);
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'preferredNumber') {
            setNumber(value);
        } else if (name === 'phoneNumber') {
            setPhoneNumber(value);
        } else if (name === 'role') {
            setRole(value);
        }

        setCrews((prevValues) => {
            const newValues = [...prevValues];
            const crewRow = newValues[index];
            console.log(crewRow, 'crewRow');
            const newRow = { ...crewRow };
            newRow[name] = value;
            newValues[index] = newRow;
            console.log(newValues, 'newValues');
            return newValues;
        });
        setIsChanged(true);
    };

    const onTagsInputChange = () => {};

    const [allMembers, setAllMembers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [lastQueryParam, setLastQueryParam] = useState('');

    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [limit, setLimit] = useState(15);
    const [lastSearchTime, setLastSearchTime] = useState(0);
    const [noResult, setNoResult] = useState(false);
    const getPCContacts = async (listboxNode, searchKey) => {
        setLoading(true);
        let response;
        let queryParams = '';
        if (searchKey && searchKey !== '') {
            queryParams += 'searchKey=' + searchKey + '&';
        }
        queryParams += searchKey && searchKey !== '' && searchKey !== null ? 'page=' + 1 : 'page=' + page;
        queryParams += searchKey && searchKey !== '' && searchKey !== null ? '&limit=' + 2000 : '&limit=' + limit;
        console.log('params=====>l', lastQueryParam);
        console.log('params=====>c', queryParams);
        if (queryParams != lastQueryParam) {
            setLastSearchTime(new Date().getUTCMilliseconds());
            setLastQueryParam(queryParams);
            response = await fetchRecord(BOOKING_REQUEST.SEARCH_PC, '', '', queryParams);

            if (response.status === 200) {
                setHasNextPage(response.data.hasNextPage);
                if (response.data.hasNextPage) setPage(response.data.nextPage);
                if (response.status === 200) {
                    setHasNextPage(response.data.hasNextPage);
                    if (response.data.hasNextPage) setPage(response.data.nextPage);

                    if (response.data.hasNextPage) setPage(response.data.nextPage);
                    console.log('params=====>c', searchKey);

                    if (searchKey && searchKey !== '' && searchKey !== null) {
                        if (response.data.docs.length === 0) {
                            setNoResult(true);
                            handleCrewChange(
                                null,
                                {
                                    fullName: searchKey,
                                    phoneNumber: phoneNumber,
                                    email: email,
                                    role: role,
                                    contactId: null,
                                    preferredNumber: number,
                                    inCrews: false,
                                    userType: 'nonCrew',
                                    _id: null,
                                },
                                null,
                            );
                            // setAllMembers([{ fullName: 'Add ' + searchKey }]);
                            // setAllMembers([
                            //    ,
                            // ]);
                        } else {
                            setNoResult(false);
                            setAllMembers([...response.data.docs]);
                        }
                        // addUniqueContacts(response.data.docs);
                    } else {
                        // setAllMembers([...response.data.docs]);
                    }
                }
                setLoading(false);
            }
        }
        setLoading(false);
    };

    const addUniqueContacts = (items) => {
        const filtered = items.filter((item) => !allMembers.includes(item));
        if (filtered.length > 0) setAllMembers([...allMembers, ...filtered]);
    };

    const numbers = [...new Array(20)];

    const getServerURI = () => {
        const reactApURI = Configs.API_URI;
        return reactApURI.substring(0, reactApURI.indexOf('/api'));
    };

    useEffect(() => {
        getPCContacts(null, null).then();
    }, []);

    // var win = window.open('/contact/' + item._id + '/details', '_blank');
    // win.focus();

    const handleViewProfile = () => {
        console.log('item', item);
        // window.open(`${getServerURI()}/crew-profile/${item._id}`, '_blank');
        if (item.fullName && item.crew) {
            Object.keys(item.crew).includes('_id')
                ? window.open(`${getServerURI()}/crew-profile/${item._id}`, '_blank')
                : window.open(`${getServerURI()}/crew-profile/${item.crew}`, '_blank');
        } else {
            window.open(`${getServerURI()}/crew-profile/${item._id}`, '_blank');
        }
    };
    const [open, setOpen] = React.useState(false);
    const [newMember, setNewMember] = React.useState(false);

    const handleInputChangeMember = (event, newInputValue) => {
        if (newInputValue.length > 0 && newInputValue !== item.fullName) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    const PopperMy = function (props) {
        return <Popper {...props} style={useStyles.popper} placement="bottom-start" />;
    };

    return (
        <Stack sx={{ backgroundColor: theme.palette.background.paper, p: 2, mb: 1 }}>
            <input type="hidden" name="contactId" value={contactId} />
            <Stack direction="row" spacing={1.5} sx={{ pb: 3, display: 'grid', gridTemplateColumns: '45% 40% 15%' }}>
                <Box>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        NAME
                    </Typography>
                    {!item.isAddedByBooker ? (
                        <Autocomplete
                            className={classes.autoCompleteInput}
                            // ListboxProps={{
                            //     onScroll: (event) => {
                            //         const listboxNode = event.currentTarget;
                            //         if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                            //             console.log('---end--->');
                            //             //setPage(page + 1);
                            //             if (searchText == null || searchText.length == 0 || searchText == '') {
                            //                 getPCContacts(listboxNode, null);
                            //             }
                            //         }
                            //     },
                            // }}
                            PopperComponent={PopperMy}
                            onClose={() => {
                                setPage(1);
                            }}
                            open={open}
                            onInputChange={handleInputChangeMember}
                            onChange={(event, newValue) => {
                                setOpen(false);
                                console.log('onChange==>', newValue);
                                if (typeof newValue === 'string') {
                                    handleCrewChange(event, {
                                        fullName: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    handleCrewChange(event, {
                                        fullName: newValue.inputValue,
                                    });
                                } else {
                                    handleCrewChange(event, newValue);
                                }
                            }}
                            disableClearable
                            getOptionDisabled={
                                (option) =>
                                    //console.log(option,'option')
                                    option &&
                                    !option.inputValue &&
                                    crews &&
                                    crews.some(({ contactId }) => contactId === option._id)
                                //crews && crews.indexOf(option._id) != -1
                            }
                            value={item.fullName}
                            options={allMembers}
                            autoHighlight
                            forcePopupIcon
                            freeSolo
                            renderOption={(option) => (
                                <React.Fragment>
                                    <Stack direction="row" spacing={1}>
                                        <Avatar
                                            className={classes.avatar}
                                            src={option && option.photoUri}
                                            alt={option && option.fullName}
                                        />
                                        <Stack direction="column" spacing={0}>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {option && option.fullName}
                                            </Typography>

                                            <Typography
                                                variant="caption"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {option && option.jobTitle}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </React.Fragment>
                            )}
                            //renderOption={(props, option) => <div {...props}>{option.fullName || option.title}</div>}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params) || [];
                                const lastSearchText = searchText;

                                // console.log('====i', params.inputValue.length);
                                const diff = Math.abs(params.inputValue.length - lastSearchText.length);
                                console.log('params.inputValue', params.inputValue);

                                if (
                                    diff > 2 ||
                                    lastSearchTime == 0 ||
                                    (params.inputValue !== '' && params.inputValue !== null) ||
                                    new Date().getUTCMilliseconds() - lastSearchTime > 10
                                ) {
                                    setSearchText(params.inputValue);

                                    getPCContacts(null, params.inputValue);
                                }
                                // if (params.inputValue !== '') {
                                //     filtered.push({
                                //         inputValue: params.inputValue,
                                //         title: `Add "${params.inputValue}"`,
                                //     });
                                // }

                                return filtered;
                            }}
                            getOptionLabel={(option) => {
                                // e.g value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.fullName;
                            }}
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    //margin="normal"
                                    placeholder="Crew Member"
                                    variant="standard"
                                    value={item.fullName}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    validators={['required']}
                                    errorMessages={['Crew member is required.']}
                                    //className={classes.nakedInput}
                                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                                />
                            )}
                        />
                    ) : (
                        <Typography className={classes.rowItemAvatar}>{crew.fullName}</Typography>
                    )}
                </Box>
                <Box>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        ROLE
                    </Typography>
                    {!isDisabled && Object.keys(item).includes('isAddedByBooker') && !item.isAddedByBooker ? (
                        <Autocomplete
                            className={classes.autoCompleteInput}
                            fullWidth
                            onChange={(event, value, reason) => handleInputChange('role', value, reason)}
                            onInputChange={handleRoleInputChange}
                            disableClearable
                            filterSelectedOptions={true}
                            value={role}
                            options={roles}
                            autoHighlight
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    //margin="normal"
                                    placeholder="Select Role"
                                    variant="standard"
                                    value={role}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    validators={['required']}
                                    errorMessages={['Role is required.']}
                                    //className={classes.nakedInput}
                                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                                />
                            )}
                        />
                    ) : (
                        <>
                            <Typography variant="body1">
                                {role && role.title}
                                {/* {roles && roles.length} */}
                            </Typography>
                        </>
                    )}
                </Box>

                <Box item xs={1}>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        PREF
                    </Typography>
                    <FormControl className={classes.formControl} sx={{ width: '100%' }}>
                        {!isDisabled &&
                            (Object.keys(item).includes('isAddedByBooker') && !item.isAddedByBooker ? (
                                <Select
                                    disabled={isDisabled}
                                    name="preferredNumber"
                                    value={number}
                                    onChange={(e) => handleInputChange('preferredNumber', e.target.value)}
                                    InputLabelProps={{ shrink: false }}
                                    className={classes.nakedInput}
                                    variant="standard"
                                >
                                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}></div> */}
                                    {numbers.map((each, index) => (
                                        <MenuItem value={index + 1}>{index + 1}</MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <Typography className={classes.rowItem}>{number}</Typography>
                            ))}
                        {isDisabled && number && (
                            <Typography className={classes.rowItem} variant="body1">
                                {number}
                            </Typography>
                        )}
                    </FormControl>
                </Box>
            </Stack>
            <Stack direction="row" spacing={1.5} sx={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                <Box>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        EMAIL
                    </Typography>
                    {(isDisabled || !newCrew) && <Typography className={classes.rowItem}>{email}</Typography>}
                    {newCrew &&
                        (Object.keys(item).includes('isAddedByBooker') && !item.isAddedByBooker ? (
                            <TextValidator
                                disabled={isDisabled}
                                //label="Email"
                                onChange={(e) => handleInputChange('email', e.target.value)}
                                placeholder="Enter email Address"
                                helperText=""
                                fullWidth
                                name="email"
                                type="text"
                                //margin="normal"
                                value={email || ''}
                                validators={['required', 'isEmail']}
                                errorMessages={['Email field is required.', 'Must be a valid Email.']}
                                autoComplete="email"
                                InputLabelProps={{ shrink: true }}
                                //InputProps={{ disableUnderline: true }}
                                variant="standard"
                                className={classes.stdInput}
                            />
                        ) : (
                            <Typography className={classes.rowItem} variant="body1">
                                {email}
                            </Typography>
                        ))}
                </Box>
                <Box className={classes.phoneDiv}>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        PHONE NUMBER
                    </Typography>
                    <PhoneField
                        disableUnderline={true}
                        className={classes.phoneInput}
                        disabled={Object.keys(item).includes('isAddedByBooker') && item.isAddedByBooker}
                        onChange={(number) => handleInputChange('phoneNumber', number)}
                        value={phoneNumber}
                        variant="standard"
                        fromContactForm
                        preferredCrewItem
                    />
                </Box>
                {/* <Typography
                    onClick={handleViewProfile}
                    style={{
                        color: item.fullName ? '#000' : '#ccc',
                        marginTop: '10px',
                        cursor: item.fullName ? 'pointer' : 'none',
                        fontSize: '12px',
                        textDecoration: 'underline',
                    }}
                >
                    {`${item.isAddedByBooker}`}
                </Typography> */}
            </Stack>
            <Stack direction="row" spacing={1.5} justifyContent="space-between" sx={{ pt: 2.25 }}>
                <Box>
                    <Typography
                        onClick={handleViewProfile}
                        variant="caption"
                        style={{
                            color: '#141414',
                            //color: item.fullName && item.crew && !noResult ? '#141414' : '#ccc',
                            marginTop: '7px',
                            //cursor: item.fullName && item.crew && !noResult ? 'pointer' : 'none',
                            // fontSize: '12px',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            textTransform: 'uppercase',
                            display: item.fullName && item.crew && !noResult ? 'block' : 'none',
                        }}
                    >
                        View Crews profile
                    </Typography>
                </Box>
                <Box>
                    {Object.keys(item).includes('isAddedByBooker') && !item.isAddedByBooker && !isDisabled && (
                        <DeleteIcon
                            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            disabled={isDisabled ? true : false}
                            onClick={() => (isDisabled ? 'javascript:;' : deletePreferredCrew(item._id))}
                        />
                    )}
                </Box>
            </Stack>
        </Stack>
    );
}

export default PreferredCrewItem;
