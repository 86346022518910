/* eslint-disable no-unused-vars */
import React from 'react';
import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import PreferredChip from './PreferredChip';
import BookingStatusChip from './BookingStatusChip';
import RatesStatusChip from './RatesStatusChip';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router';

function BookingItem({ member }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const handleItemClick = () => {
        navigate(`/booking-details/${member._id}`);
    };

    return (
        <>
            <Stack direction="column" spacing={3} bgcolor={theme.palette.background.paper} mb={'0.571rem'}>
                <Grid container pl={1.95} pr={1.5} pb={1} pt={1.33}>
                    <Grid container item xs={10} justifyContent="flex-start">
                        <Stack direction="row" spacing={1.35} alignItems="center">
                            <Avatar
                                alt={member.productionInfo.title}
                                src={member.productionInfo.thumbnailImageUri}
                                sx={{ width: 40, height: 40 }}
                            />
                            <Stack direction="column" spacing={0.45}>
                                <Typography variant="h4">{member.productionInfo.title}</Typography>
                                <Typography variant="caption" className="uppercase">
                                    {member.productionInfo.client ? member.productionInfo.client.title : ''}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid container item xs={2} justifyContent="flex-end">
                        <IconButton
                            onClick={handleItemClick}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="back button"
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider sx={{ m: '0!important' }} />
                <Stack direction="column" spacing={0.12} sx={{ m: '0!important', px: '1.2rem', py: '1rem' }}>
                    <Grid container>
                        <Grid item xs={6} justifyContent="flex-start" alignItems="center">
                            <Typography variant="overline">Accept Booking Dates</Typography>
                        </Grid>
                        <Grid item xs={6} justifyContent="flex-start" alignItems="center">
                            <BookingStatusChip showSmallLabel={true} booking={member} />
                        </Grid>
                    </Grid>
                    <Grid container style={{ visibility: member.provideCrewPreferences ? 'visible' : 'hidden' }}>
                        <Grid item xs={6} showSmallLabel={true} justifyContent="flex-start">
                            <Typography variant="overline">Submit Preferred Crew</Typography>
                        </Grid>
                        <Grid item xs={6} justifyContent="flex-start" alignItems="center">
                            <PreferredChip showSmallLabel={true} booking={member} />
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </>
    );
}

export default BookingItem;
