import React, {useEffect, useState} from 'react';
import {AppBar, Grid, IconButton, Stack, Toolbar, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as CalendarIcon} from '../../assets/images/icons/calendar-icon.svg';
import {useNavigate} from 'react-router';
import {getLoggedInUser} from '../../services/Storage';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {logout} from '../../services/Authorize';
import SimpleBackdrop from '../../components/Common/SimpleBackdrop';

const Header = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('User');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = async () => {
        setLoading(true);
        setAnchorEl(null);
        let response = await logout();
        if (response.status === 200) {
            setLoading(false);
            navigate('/login');
        }
    };
    useEffect(() => {
        console.log('==getLoggedInUser==>', getLoggedInUser());
        setFullName(getLoggedInUser().fullName);
    }, []);
    return (
        <AppBar position="sticky" elevation={0} sx={{ backgroundColor: theme.palette.background.default }}>
            {loading && <SimpleBackdrop />}
            <Toolbar sx={{ minHeight: '107px', alignItems: 'flex-end', py: '.5rem' }}>
                <Grid container>
                    <Grid container item xs={9} alignItems="center">
                        <Stack direction="column" spacing={1} px={0.5}>
                            <Typography variant="body2">CREWS - BOOKING REQUEST MANAGER</Typography>
                            <Typography variant="h1" sx={{ mt: '.215rem!important', mb: '.75rem!important' }}>
                                Hi {fullName},
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid container item xs={3} justifyContent="flex-end">
                        <Stack
                            direction="row"
                            alignItems="flex-end"
                            alignSelf="flex-end"
                            spacing={1}
                            sx={{ pb: '0.429rem' }}
                        >
                            <IconButton
                                onClick={() => navigate('/mycalendar')}
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="calendar icon"
                            >
                                <CalendarIcon className="icon-outlined" />
                            </IconButton>
                            <IconButton
                                onClick={handleClick}
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="more icon"
                                sx={{ mr: '-12px!important', mb: '-1px!important' }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                open={open}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleClose}>Logout</MenuItem>
                            </Menu>
                        </Stack>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
export default Header;
