const VALIDATION_RULES = {
    EMAIL_REGEX:
        'matchRegexp:^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$',
    PHONE_REGEX: 'matchRegexp:^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$',
    POSTCODE_REGEX: 'matchRegexp:^[0-9][0-9- ]{0,10}[0-9]$',
    URL_REGEX:
        "matchRegexp:^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
    NAME_REGEX: 'matchRegexp:^([a-zA-Z0-9 ]+$)',
    PROTOCOL_URL_REGEX:
        "matchRegexp:^(?:http(s)?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
};

const USER_TYPE = {
    FREELANCER: 'freelancer',
    FREELANCE_BOOKER: 'freelanceBooker',
    COMPANY_STANDARD: 'companyStandard',
    COMPANY_VENDOR: 'companyVendor',
    COMPANY: 'company',
    COMPANY_ADMIN: 'administrator',
};
export const EDITOR = 'Editor';

export const NOTIFICATION_STATUS = {
    REQUESTED: 'requested',
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
};

export const CONNECT = 'connect';
export const COMPLETE = 'complete';
export const ON_HOLD = 'on_hold';
export const HOLD = 'hold';
export const LIVE = 'live';
export const CANCELLED = 'cancelled';
export const LIGHT_HOLD = 'light_hold';
export const CONFIRMED = 'confirmed';
export const UNCONFIRMED = 'unconfirmed';
export const DECLINED = 'declined';
export const ACCEPTED = 'accepted';
export const UNACCEPTED = 'unaccepted';
export const ATTACHMENTS = 'attachments';
export const BOOKING_DATES = 'booking_dates';
export const BOOKING_STATUS_NOTIFICATION = 'booking_status';
export const BOOKING_REQUEST = 'booking_request';
export const BOOKING_REQUEST_RESPONSE = 'booking_request_response';
export const BOOKING_REQUEST_UPDATE = 'booking_request_update';
export const UNREGISTERED = 'unregistered';
export const APPRECIATE_PROJECT = 'appreciate_project';
export const CREDIT_MEMBER = 'credit_member';
export const CREDIT = 'credit';
export const CONTACT_MEMBER = 'contact_member';
export const CALENDAR_ACCESS = 'calendar_access';
export const ALL_PRODUCERS = 'allProducers';
export const PRODUCERS_IN_NETWORK = 'producersInNetwork';
export const SPECIFIC_CREWS = 'specificCrews';
export const NON_CREW_ACCEPTED_INVITATION = 'non_crew_accepted_invitation';
export const AVAILABILITY_CHECK_RESPONSE = 'availability_check_response';
export const BOOKING_REQUEST_NEW_FLOW = 'booking_request_new_flow';
export const CREW_RESPONDED_ON_BOOKING_DATES = 'crew_responded_on_booking_dates';
export const CREW_PREFERENCES_TO_BOOKER = 'crew_preferences_to_booker';
export const CREW_SUBMITTED_RATES = 'crew_submitted_rates';
export const CREW_RESPONDED_RATES = 'crew_responded_rates';
export const BOOKER_RESPONDED_RATES = 'booker_responded_rates';

export const BOOKER_CHANGED_RATES = 'booker_changed_rates';
export const CREW_CHANGED_RATES = 'crew_changed_rates';
export const BOOKER_CHANGED_DATES = 'booker_changed_dates';
export const CREW_CHANGED_DATES = 'crew_changed_dates';
export const BOOKER_CHANGED_PREFERENCES = 'booker_changed_preferences';
export const CREW_CHANGED_PREFERENCES = 'crew_changed_preferences';
export const CREW_SUBMITTED_PREFERENCES = 'crew_submitted_preferences';

export const PRODUCTION_STATUS = [
    {
        key: LIVE,
        value: 'Live',
    },
    {
        key: ON_HOLD,
        value: 'On Hold',
    },
    {
        key: COMPLETE,
        value: 'Complete',
    },
    {
        key: CANCELLED,
        value: 'Cancelled',
    },
];
export const PRODUCTION_TYPES = [
    {
        key: 'offshore_feature_film',
        value: 'Offshore Feature Film',
    },
    {
        key: 'offshore_tv_drama',
        value: 'Offshore TV Drama',
    },
    {
        key: 'local_feature_film',
        value: 'Local Feature Film',
    },
    {
        key: 'local_tv_web_erama_doco_low_budget_film',
        value: 'Local TV / Web / Drama / Doco / Low Budget Film',
    },
    {
        key: 'commercial_advertising_tv_online_content',
        value: 'Commercial Advertising TV / Online / Content',
    },
    {
        key: 'corporate_production_corporate_online_in_house',
        value: 'Corporate Production / Corporate Online / in-house',
    },
    {
        key: 'charity_not_for_profit_spec_ads_social_organisations_short_films_other_low_budget',
        value: 'Charity / Not For Profit / Spec Ads / Social Organisations / Short Films / Other low budget',
    },
    {
        key: 'commercial_tv',
        value: 'Commercial TV',
    },
];

export const BOOKING_STATUS = [
    {
        key: HOLD,
        value: 'Hold',
    },
    {
        key: LIGHT_HOLD,
        value: 'Light Hold',
    },
    {
        key: CONFIRMED,
        value: 'Confirmed',
    },
];

export const BOOKING_CONFIRMATION_STATUS = [
    {
        key: CONFIRMED,
        value: 'Confirmed',
    },
    {
        key: DECLINED,
        value: 'Declined',
    },
    {
        key: ACCEPTED,
        value: 'Accepted',
    },
];

export const TEXT_FIELD_MAX_LENGTH = 50;

export const MAX_IMAGE_UPLOAD_SIZE = 0.8 * 1000 * 1024;
export const MAX_PROFILE_IMAGE_UPLOAD_SIZE = 3 * 1024 * 1024;
export const MAX_COMPANY_IMAGE_UPLOAD_SIZE = 2 * 1024 * 1024;

export { VALIDATION_RULES, USER_TYPE };

export const NO_RECORDS_FOUND = 'No Records Found.';
export const LOADING = 'Loading...';

export const PRODUCTION_DIRECTORY = 'productions';

export const CALL_SHEET_DIRECTORY = 'callSheets';
export const CURRENCY = 'USD';

export const BOOKING_REQUEST_MESSAGE_LIGHT_HOLD =
    'A light hold is considered an early-stage hold. Booking details have high potential to change while the project is still in the early pre-production stage, or may not yet be considered a green-lit project.';
export const BOOKING_REQUEST_MESSAGE_HOLD =
    'A hold is considered a green-lit booking, but still with some potential for booking details to change.';
export const BOOKING_REQUEST_MESSAGE_CONFIRM = 'The booking is confirmed, with the date and time considered locked in.';
export const BOOKING_REQUEST_MORE_INFO_LINK = 'https://help.crews.co/article/booking-status/';
export const BOOKING_REQUEST_WHAT_WILL_THEY_SEE_LINK =
    'https://help.crews.co/article/what-will-crew-members-see-in-their-booking-request/';

export const HELP_LINK = 'https://help.crews.co/';
export const CREW_ORDERING_LINK =
    'https://help.crews.co/article/how-to-add-a-project/#credit-list-ordering-manual-vs-automatic';

export const BOOKING_DATES_FORMAT = 'DD-MM-YYYY';

export const WHAT_DESCRIBERS_YOU_GET_BOOKED = 'getBooked';
export const WHAT_DESCRIBERS_YOU_BOOK_CREW = 'bookCrew';
export const WHAT_DESCRIBERS_YOU_BOTH = 'both';
export const FREELANCER = 'freelancer';
export const COMPANY_STANDARD = 'companyStandard';
export const COMPANY_VENDOR = 'companyVendor';

export const COMPANY_CARD_DEMO_NAME = 'We Know Video';
export const COMPANY_CARD_DEMO_IMAGE_URI = 'https://crews-prod-items.s3.amazonaws.com/common/11.jpg';
export const COMPANY_CARD_DEMO_PHOTO_URI =
    'https://crews-prod-items.s3.amazonaws.com/common/WKV-O_Social-Icon_Navy.png';
export const COMPANY_CARD_DEMO_COMPANY_TYPE = 'Production Company';
export const COMPANY_CARD_DEMO_ADDRESS = 'Sydney NSW, Australia';

export const UNPUBLISHED = 'unpublished';
export const SENT = 'sent';
export const AVAILABLE = 'available';
export const UNAVAILABLE = 'unavailable';
export const BOOKING_CONFLICT = 'bookingConflict';
export const ON_LEAVE = 'onLeave';
export const PARTIALLY_UNAVAILABLE = 'partiallyUnavailable';
export const OTHER = 'other';
export const CALL_TO_DISCUSS = 'callToDiscuss';

export const QUOTE_HOLD = 'quoteHold';
export const LIGHT_HOLD_RESPONSE = 'lightHold';
export const UNCONFIRMED_HOLD = 'unconfirmedHold';
export const WEATHER_HOLD = 'weatherHold';
export const CALL_BOOKING_AGENT = 'callBookingAgent';

export const ALL = 'all';
export const UNSENT_AVAILABILITY_CHECK = 'unSentAvailabilityChecksOnly';
export const UNSENT_BOOKING_REQUEST = 'unSentBookingRequestOnly';
export const UPDATES_ONLY = 'updatesOnly';

export const FIRST_HOLD = '1st Holds';
export const SECOND_HOLD = '2nd Holds';
export const BOOKING_REQUEST_SAVE_SUCCESS = 'Booking Request Saved';

export const RATE_CARD_TO_DO = 'TO_DO';
export const RATE_CARD_UNPUBLISHED_CHANGES = 'UNPUBLISHED_CHANGES';
export const RATE_CARD_REQUESTED_TO_CREW = 'REQUESTED_TO_CREW';
export const RATE_CARD_SUBMITTED_TO_CREW = 'SUBMITTED_TO_CREW';
export const RATE_CARD_SUBMITTED_BY_CREW = 'SUBMITTED_BY_CREW';
export const RATE_CARD_CHANGES_SUBMITTED_BY_CREW = 'CHANGES_SUBMITTED_BY_CREW';
export const RATE_CARD_CHANGES_SUBMITTED_BY_BOOKER = 'CHANGES_SUBMITTED_BY_BOOKER';
export const RATE_CARD_APPROVED_BY_CREW = 'APPROVED_BY_CREW';
export const RATE_CARD_APPROVED_BY_BOOKER = 'APPROVED_BY_BOOKER';
export const RATE_CARD_REJECTED_BY_CREW = 'REJECTED_BY_CREW';
export const RATE_CARD_NEEDS_DISCUSSION_BY_CREW = 'NEEDS_DISCUSSION_BY_CREW';
export const RATE_CARD_REJECTED_BY_BOOKER = 'REJECTED_BY_BOOKER';
export const RATE_CARD_NEEDS_DISCUSSION_BY_BOOKER = 'NEEDS_DISCUSSION_BY_BOOKER';

export const BOOKING_DATES_STATUS_ACCEPTED = 'Accepted';
export const BOOKING_DATES_STATUS_TO_DO = 'To Do';

export const PasswordValidation = {
    validators: [
        'required',
        'minStringLength:8',
        'maxStringLength:30',
        'matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
    ],
    errorMessages: [
        'Password is required.',
        'Password must be at least 8 characters long.',
        'Password cannot be greater than 30 characters long.',
        'Password must contain an uppercase and lowercase letter and a number.'
    ]
};

export const RELEASE_STATUS_NONE = 'none';
export const RELEASE_STATUS_RELEASED = 'released';
export const RELEASE_STATUS_CONFIRMED = 'confirmed';
export const RELEASE_STATUS_UNCONFIRMED = 'unconfirmed';

//copied from web

export const FIRST_HOLD_COLOR = '#F5E3A4';
export const FIRST_HOLD_TEXT = '#b9832b';
export const CONFIRMED_HOLD_COLOR = '#BFEADE';
export const CONFIRMED_HOLD_TEXT = '#379B87';
export const SECOND_HOLD_COLOR = '#A4E2F5';
export const SECOND_HOLD_TEXT = '#2F9CBD';

export const DEFAULT_CHIP_COLOR = '#E6E6E6';
export const DEFAULT_CHIP_TEXT = '#777777';
export const SUCCESS_CHIP_COLOR = '#BFEADE';
export const SUCCESS_CHIP_TEXT = '#379B87';
export const WARNING_CHIP_COLOR = '#F5E3A4';
export const WARNING_CHIP_TEXT = '#b9832b';
export const INFO_CHIP_COLOR = '#A4E2F5';
export const INFO_CHIP_TEXT = '#2F9CBD';
export const ERROR_CHIP_COLOR = '#EABFC8';
export const ERROR_CHIP_TEXT = '#AA4D61';
