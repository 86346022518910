/* eslint-disable no-unused-vars */
import React from 'react';
import { AppBar, Box, Button, Grid, Link, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/images/logos/logo-white.svg';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const Home = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    console.log(theme);
    return (
        <>
            <AppBar position="fixed" color="transparent" elevation={0}>
                <Toolbar>
                    <Grid container>
                        <Grid container item xs={12} alignItems="center" justifyContent="center">
                            <Box sx={{ marginTop: '5px' }}>
                                <Logo />
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box
                sx={{ display: 'flex', height: '100vh', backgroundColor: theme.palette.text.primary }}
                px={4}
                alignItems="center"
                justifyContent="center"
            >
                <Stack direction="column" spacing={3.12} textAlign="center">
                    <Stack direction="column" spacing={1.125}>
                        <Typography variant="h1" color={theme.palette.common.white}>
                            Booking Request Manager
                        </Typography>
                        <Typography variant="body2" component="p" color={theme.palette.common.white}>
                            View your booking requests, accept hold dates
                        </Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Button onClick={() => navigate('/login-one-time')} variant="contained">
                            One Time Login
                        </Button>
                        <Button onClick={() => navigate('/signup')} variant="contained">
                            Sign Up To Crews{' '}
                        </Button>
                        <Link
                            to="/login"
                            variant="body2"
                            component={RouterLink}
                            sx={{ color: theme.palette.common.white, textDecorationColor: theme.palette.common.white }}
                        >
                            Already have a login?
                        </Link>
                    </Stack>
                </Stack>
            </Box>
            {/* <BottomNavigation
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, p: 2, borderRadius: 0, display: 'block' }}
            >
                <Alert variant="outlined" severity="warning" icon={false}>
                    Changes made will reset previous approvals and overwrite previous versions
                </Alert>

                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="stretch">
                    <Button variant="contained" color="grey" size="small">
                        Decline All
                    </Button>
                    <Button variant="text" disableRipple>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" size="small">
                        Submit
                    </Button>
                    <Button variant="contained" color="primary" size="small">
                        Accept All
                    </Button>
                    <Select color="primary" variant="filled" IconComponent={KeyboardArrowDownIcon} defaultValue={0}>
                        <MenuItem value={0}>Approve</MenuItem>
                        <MenuItem value={1}>Decline</MenuItem>
                    </Select>
                </Stack>
            </BottomNavigation> */}
        </>
    );
};

export default Home;
