import '@fullcalendar/core/main.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/daygrid/main.css';
import listPlugin from '@fullcalendar/list';
import '@fullcalendar/list/main.css';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import '@fullcalendar/timegrid/main.css';
import timelinePlugin from '@fullcalendar/timeline';
import { useMediaQuery, useTheme, Paper } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { EVENTS } from '../../constants/ApiEndPoint';
import { COMMON_CODES } from '../../constants/StatusCode';
import { deleteRecord, fetchRecord, postRecord } from '../../services/Axios';
import Toolbar from './Toolbar';
import Header from './Header';
import { getSelectedBooking } from '../../services/Storage';
import PopUp from './PopUp';

function Calendar(props) {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const code = urlParams.get('code');
    const [reqId, setReqId] = useState('');
    const [selectedEvent, setSelectedEvent] = useState('');

    const calendarRef = useRef(null);
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [view, setView] = useState(mobileDevice ? 'dayGridMonth' : 'dayGridMonth');
    const [date, setDate] = useState(moment().toDate());
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState([]);
    const [filters, setFilters] = useState(false);
    const [eventModal, setEventModal] = useState({
        open: false,
        event: null,
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleEventNew = () => {
        setEventModal({
            open: true,
            event: null,
        });
    };

    const handleEventDelete = async (e) => {
        setEventModal({
            open: false,
            event: null,
        });
        setAnchorEl(null);
        const response = await deleteRecord(EVENTS.DELETE, '', event.id);

        if (response) {
            if (response.status === COMMON_CODES.SUCCESS) {
            }
        }
    };
    const handleEventEdit = (e) => {
        const currentEvent = {
            _id: event.id,
            title: event.title,
            description: event._def.extendedProps.description,
            start: event._def.extendedProps.startDate,
            end: event._def.extendedProps.endDate,
            allDay: event.allDay,
            color: event.backgroundColor,
            generate: event._def.extendedProps.generate,
        };
        setEventModal({
            open: true,
            event: currentEvent,
        });
        setAnchorEl(null);
    };

    const handlePopoverOpen = (info) => {
        if (isOpen) {
            setIsOpen(false);
        }
        setEvent(info.event);
        let { top, left, right } = info.el.getBoundingClientRect();
        let anchorEl = {
            top,
            left,
            right,
        };
        if (left > 200) {
            anchorEl = {
                top: parseInt(top - 80),
                right: parseInt(right - left - 50),
            };
        } else {
            anchorEl = {
                top: parseInt(top - 80),
                left: parseInt(left),
            };
        }
        setAnchorEl(anchorEl);
        setTimeout(() => {
            setIsOpen(true);
        }, 0);
    };

    const handlePopoverClose = () => {
        if (isOpen) {
            setAnchorEl(null);
            setIsOpen(false);
        }
    };

    const open = Boolean(anchorEl);

    const handleDateToday = () => {
        setAnchorEl(null);
        const calendarApi = calendarRef.current.getApi();
        calendarApi.today();
        setDate(calendarApi.getDate());
    };

    const handleViewChange = (newView) => {
        setAnchorEl(null);
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(newView);
        setView(newView);
    };

    const handleDatePrev = () => {
        setAnchorEl(null);
        const calendarApi = calendarRef.current.getApi();
        calendarApi.prev();
        setDate(calendarApi.getDate());
    };

    const handleDateNext = () => {
        setAnchorEl(null);
        const calendarApi = calendarRef.current.getApi();
        calendarApi.next();
        setDate(calendarApi.getDate());
    };

    let params = {
        date: moment(date).format('YYYY-MM-DD'),
    };

    const fetchEvents = async () => {
        let queryParams = '';
        for (const key in params) {
            if (queryParams !== '' && params[key]) {
                queryParams += '&';
            }
            queryParams += params[key] ? key + '=' + encodeURIComponent(params[key]) : '';
        }
        const response = await fetchRecord(EVENTS.LIST, '', '', queryParams);
        if (response && response.data && response.data) {
            if (response.status === COMMON_CODES.SUCCESS) {
                const events = response.data;
                const mappedEvents = events.map((event) => {
                    return {
                        ...event,
                        title: event.bookingDates
                            ? event.title +
                              '\n' +
                              event.taskTypeInfo.title +
                              '\n' +
                              event.holdTypeInfo.title.split('(')[1].split(')')[0]
                            : event.title,
                        originalTitle: event.title,
                    };
                });
                setEvents(mappedEvents);
                setSelectedEvent(getSelectedBooking());
            }
        } else if (response && response.status === COMMON_CODES.NO_RECORDS) {
            setEvents([]);
        }
    };

    const setToken = async (code) => {
        const response = await postRecord(EVENTS.AUTHORIZE, { code: code });
    };
    if (code) {
        setToken(code).then();
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const reqId = urlParams.get('reqId');
        console.log('--reqId-->', reqId);
        setReqId(reqId);
        fetchEvents().then();
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const reqId = urlParams.get('reqId');
        console.log('--reqId-->', reqId);
        setReqId(reqId);
        fetchEvents().then();
    }, [date, filters, reqId]);

    return (
        <>
            <Header chipVisible={reqId == null ? false : true} booking={selectedEvent} />

            <Paper
                sx={{ mt: '0.571rem!important', px: '1.143rem', backgroundColor: 'transparent' }}
                elevation={0}
                onClick={handlePopoverClose}
                onScroll={handlePopoverClose}
            >
                <Toolbar
                    date={date}
                    onDateNext={handleDateNext}
                    onDatePrev={handleDatePrev}
                    onDateToday={handleDateToday}
                    onEventAdd={handleEventNew}
                    onViewChange={handleViewChange}
                    view={view}
                />
                <FullCalendar
                    displayEventTime={false}
                    defaultDate={date}
                    defaultView={view}
                    editable
                    eventClick={handlePopoverOpen}
                    events={events}
                    header={false}
                    height={560}

                    contentHeight="auto"
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, timelinePlugin]}
                    ref={calendarRef}
                    rerenderDelay={10}
                    selectable
                    weekends
                    
                />
                {open && (
                    <PopUp
                        onDelete={handleEventDelete}
                        onEdit={handleEventEdit}
                        onCancel={handlePopoverClose}
                        event={event}
                        anchorEl={anchorEl}
                        onClick={handlePopoverClose}
                    ></PopUp>
                )}
            </Paper>
        </>
    );
}

export default Calendar;
