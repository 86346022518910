import {Box, Button, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {AVAILABILITY_CHECK} from 'src/constants/ApiEndPoint';
import {fetchRecord} from 'src/services/Axios';

import BookingRateForm from './BookingRateForm';
import {ReactComponent as AddIcon} from '../../../../assets/images/icons/add-icon.svg';
import theme from 'src/assets/styles/theme';
import {PRODUCTION_TYPES} from 'src/constants';

const useLocalStyles = makeStyles((theme) => ({
    // root: {
    //     width: '100%',
    //     maxWidth: 500,
    // },
    // cartHeader: {
    //     padding: theme.spacing(2, 0),
    // },
    // option: {
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     //padding: theme.spacing(2),
    //     '& + &': {
    //         marginTop: '8px',
    //     },
    // },
    // headingWrap: {
    //     color: '#6e6969 !important',
    //     fontSize: '11px !important',
    //     fontWeight: '400 !important',
    //     // padding: '10px !important'
    //     // width: '85px'
    // },
    // titleWrap: {
    //     padding: '16px 16px 16px 10px',
    //     // width: '60px',
    //     color: '#6e6969 !important',
    //     fontSize: '11px !important',
    //     fontWeight: '400 !important',
    // },
    // tableWrap: {
    //     // height: '400px',
    //     boxShadow: 'none !important',
    //     '& .MuiTable-root': {
    //         '& .MuiTableRow-root': {
    //             display: 'grid',
    //             gridTemplateColumns: '23% 18% 15% 25% 13% 6%',
    //             width: '100%',
    //         },
    //     },
    // },
    // typography: {
    //     fontSize: '11px',
    // },
}));
function getProductionTypeLabel(type){
    let productionTypeLable = type
    PRODUCTION_TYPES.forEach(productionType => {
      if(productionType.key === type){
        productionTypeLable = productionType.value
      }
    });
    return productionTypeLable;
  }

export default function BookingRates(props) {
    const classes = useLocalStyles();

    const {
        rates,
        onAddKeyDates,
        removeKeyDates,
        handleFieldChange,
        onRatesValueChange,
        emptyValues,
        onKeyDatesValueChange,
        hoursList,
        otRateList,
        taxList,
        requestRates,
        booking,
        initialRequestedRates,
        production
    } = props;
    const [tasks, setTasks] = useState([]);
    const [taskTypeCount, setTaskTypeCount] = useState([]);

    const fetchTaskTypes = async () => {
        let response = await fetchRecord(AVAILABILITY_CHECK.GET_ALL_TASK_TYPES);
        console.log('response --task type-->', response);
        if (response.status === 200) {
            setTasks(response.data);
        }
    };

    useEffect(()=>{
        console.log("=====booking======>",booking);
        mapWithDate();
        taskTypeAndCounts();

    },[rates])

    const getDaysDiff = (start_date, end_date, date_format = 'YYYY-MM-DD') => {
        const getDateAsArray = (date) => {
            return moment(date.split(/\D+/), date_format);
        };
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1;
    };

    const [mappedDates,setMappedDates]=useState([]);

    const mapWithDate=()=>{
        const mapped=booking.bookingDates.map((item)=>{


            return {
                name:item.taskTypeInfo.title,
                diff:getDaysDiff(item.startDate,item.endDate)

            }
        })
        setMappedDates(mapped);
        console.log("mapped====>",mapped)
    }


    const taskTypeAndCounts=()=>{
        const bookingDates=booking.bookingDates;

        var arr=[];
        for(var i=0;i<bookingDates.length;i++){
            console.log("rates===i>",rates[i]);
           
          arr.push(bookingDates[i].taskTypeInfo.title);
        }
        console.log("rates===arr>",arr);
        if(arr.length>0){
            const occurrences = arr.reduce(function (acc, curr) {
                return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
              }, {});
              
              var result = Object.keys(occurrences).map((key) => [key, occurrences[key]]);
              console.log("rates===o>",result);
              setTaskTypeCount(result);
            }
        
            
    }

    useEffect(() => {
        console.log('rates==>', rates);
       
        fetchTaskTypes().then();
        
    }, []);
    

    return (
        <div style={{ marginBottom: '10rem' }}>
            <TableContainer>
                {/* {booking.isInitialRequestedRates && !booking.hasCrewSubmittedRates && (
                    <Alert
                        variant="outlined"
                        severity="warning"
                        icon={false}
                        sx={{ mb: '0.571rem', maxWidth: '241px', marginLeft: 'auto', marginRight: 'auto' }}
                    >
                        The booker has requested you submit your rates for approval.
                    </Alert>
                )} */}
                <Table
                    aria-label="spanning table"
                    sx={{ mb: '0.571rem', backgroundColor: theme.palette.background.paper }}
                >
                    <TableRow>
                        <TableCell>
                            <Typography variant="overline" component="div" sx={{ mb: '0.357rem', pt: '.5rem' }}>
                                Project Overview
                            </Typography>
                            <Typography
                                variant="overline"
                                component="div"
                                sx={{
                                    color: theme.palette.text.primary,
                                    lineHeight: '1rem',
                                    textTransform: 'uppercase',
                                    pb: '0.357rem',
                                }}
                            >
                               {getProductionTypeLabel(production.productionType[0]) || ''}
                                {/* <br />1 x Recce
                                <br />2 x Shoot Days */}
                                
                                <br />
                                {mappedDates.map((item)=>(
                                    <>
                                     <>{item.diff}</>
                                     &nbsp;
                                     <>X</>
                                     &nbsp;
                                     <>{item.name}</>
                                     <br/>
                                     </>
                                ))}
                                
    
                               
                                
                            </Typography>
                        </TableCell>
                    </TableRow>
                </Table>
                <Table aria-label="spanning table" sx={{ backgroundColor: theme.palette.background.paper }}>
                    <TableBody>
                        {rates.map((row, index) => (
                            <BookingRateForm
                                item={row}
                                remove={removeKeyDates}
                                onRatesValueChange={onRatesValueChange}
                                handleFieldChange={handleFieldChange}
                                index={index}
                                emptyValues={emptyValues}
                                onKeyDatesValueChange={onKeyDatesValueChange}
                                tasks={tasks}
                                hoursList={hoursList}
                                otRateList={otRateList}
                                taxList={taxList}
                                requestRates={requestRates}
                                isInitialRequestedRates={initialRequestedRates}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {requestRates && (
                <Box sx={{ mt: '0.5rem' }}>
                    <Button
                        variant="text"
                        color="primary"
                        disableRipple
                        startIcon={<AddIcon />}
                        onClick={() => onAddKeyDates('rates')}
                        sx={{ textDecoration: 'underline', fontSize: '0.786rem' }}
                    >
                        Add New Line Item
                    </Button>
                </Box>
            )}
        </div>
    );
}
