/* eslint-disable no-unused-vars */
import React from 'react';
import { Chip, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
// import PreferredChip from './PreferredChip';
// import BookingStatusChip from './BookingList/BookingStatusChip';
// import RatesStatusChip from './RatesStatusChip';
import { useTheme } from '@mui/material/styles';
// import Avatar from '@mui/material/Avatar';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router';
// import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';

const FIRST_HOLD = '1st Holds';
const SECOND_HOLD = '2nd Holds';

function BookingDatesItem(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    // const handleItemClick = () => {
    //     navigate(`/booking-details/${member._id}`);
    // };

    const getDisabledResponse = () => {
        console.log('editMode==>', editMode);
        console.log('isSubmitted==>', isSubmitted());
        console.log('isDraftCheck==>', isDraftCheck());
        //return !editMode || isSubmitted() || isDraftCheck();

        return !editMode || isDraftCheck();
    };

    const { item, getResponseObject, handleChange, handleInputChange, isSubmitted, isDraftCheck, responses, editMode } =
        props;

    const getDaysDiff = (start_date, end_date, date_format = 'YYYY-MM-DD') => {
        const getDateAsArray = (date) => {
            return moment(date.split(/\D+/), date_format);
        };
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1;
    };

    const getFullHoldTypeName = (title) => {
        return `${title.substring(0, title.indexOf('('))} — ${title.substring(
            title.indexOf('(') + 1,
            title.indexOf(')'),
        )}`;
    };

    const getHoldTypeColor = (title) => {
        if (title.includes('Confirm')) {
            return 'success';
        } else if (title.includes('2nd')) {
            return 'info';
        } else {
            return 'warning';
        }
    };

    return (
        <>
            <Stack
                spacing={1}
                sx={{ mt: '0.571rem!important', mb: '0!important', backgroundColor: theme.palette.background.paper }}
            >
                <Grid container px={2} pt={2.5} pb={3} rowSpacing={1.2}>
                    <Grid item xs={12}>
                        {item.isFlexible ? (
                            <Typography
                                variant="h1"
                                sx={{ fontSize: '1.714rem', lineHeight: '2.287rem', textTransform: 'capitalize' }}
                            >
                                {item.taskTypeInfo.title} — {item.bookingDateCount}&nbsp;
                                {item.bookingDateCountType}
                            </Typography>
                        ) : (
                            <Typography variant="h1" sx={{ fontSize: '1.714rem', lineHeight: '2.287rem' }}>
                                {item.taskTypeInfo.title} —{' '}
                                {getDaysDiff(
                                    moment(item.startDate).format('YYYY-MM-DD'),
                                    moment(item.endDate).format('YYYY-MM-DD'),
                                )}
                                {getDaysDiff(
                                    moment(item.startDate).format('YYYY-MM-DD'),
                                    moment(item.endDate).format('YYYY-MM-DD'),
                                ) == 1
                                    ? ' Day'
                                    : ' Days'}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Chip
                            label={item.holdTypeInfo.title ? getFullHoldTypeName(item.holdTypeInfo.title) : ''}
                            color={item.holdTypeInfo.title ? getHoldTypeColor(item.holdTypeInfo.title) : 'default'}
                            style={{ height: '20px' }}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="caption"
                            component="p"
                            sx={{ lineHeight: '1.143rem', marginBottom: '0!important' }}
                        >
                            {item.holdTypeInfo.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {item.isFlexible ? (
                            <Typography
                                variant="overline"
                                sx={{
                                    color: '#141414',
                                    fontSize: '12px',
                                }}
                            >
                                FLEXIBLE BLOCK
                            </Typography>
                        ) : (
                            <Typography
                                variant="overline"
                                sx={{
                                    color: '#141414',
                                    fontSize: '12px',
                                }}
                            >
                                <span>{moment(item.startDate).format('ddd, Do MMMM')}</span> <span>—</span>{' '}
                                <span>{moment(item.endDate).format('ddd, Do MMMM')}</span>
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ margin: '0!important' }} />
                <Grid container px={2} py={3} sx={{ m: '0!important' }}>
                    <Grid item xs={12}>
                        <Autocomplete
                            size="small"
                            onChange={(event, value) => handleChange(value, item)}
                            onInputChange={handleInputChange}
                            disableClearable
                            disabled={getDisabledResponse()}
                            value={
                                item.receiverAcceptance.title
                                    ? getResponseObject(item.receiverAcceptance.value)
                                    : getResponseObject(item.receiverAcceptance)
                            }
                            options={responses}
                            autoHighlight
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // placeholder={item.taskType || item.tag ? '' : 'Choose'}
                                    label="My Response*"
                                    placeholder="Select your acceptance"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {/* <Grid container px={2} pt={2.5} pb={1.5} columnSpacing={3}>
                    <Grid item xs={7}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.357rem' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                }}
                            >
                                <Typography variant="overline">Dates</Typography>
                                <Typography variant="overline" sx={{ color: theme.palette.text.primary }}>
                                    {getDaysDiff(
                                        moment(item.startDate).format('YYYY-MM-DD'),
                                        moment(item.endDate).format('YYYY-MM-DD'),
                                    )}
                                    {getDaysDiff(
                                        moment(item.startDate).format('YYYY-MM-DD'),
                                        moment(item.endDate).format('YYYY-MM-DD'),
                                    ) == 1
                                        ? ' DAY'
                                        : ' DAYS'}
                                </Typography>
                            </Box>

                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: '1.714rem',
                                    lineHeight: '2.287rem',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>{moment(item.startDate).format('DD-MM-YY')}</span> <span>-</span>{' '}
                                <span>{moment(item.endDate).format('DD-MM-YY')}</span>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.357rem' }}>
                            <Typography variant="overline">TASK TYPE</Typography>
                            <Typography variant="h1" sx={{ fontSize: '1.714rem', lineHeight: '2.287rem' }}>
                                {item.taskTypeInfo.title}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid> */}
                {/* <Divider sx={{ margin: '0!important' }} /> */}
                {/* <Box
                    px={2}
                    sx={{
                        pt: '1.1rem',
                        pb: '1rem!important',
                        m: '0!important',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.51rem',
                    }}
                >
                    <Typography variant="overline" component="div" sx={{ mb: '.1rem' }}>
                        HOLD TYPE
                    </Typography>
                    <Typography
                        variant="h1"
                        className={
                            item.holdTypeInfo.holdTypeCategory === FIRST_HOLD
                                ? 'firstHold'
                                : item.holdTypeInfo.holdTypeCategory === SECOND_HOLD
                                    ? 'secondHold'
                                    : item.holdTypeInfo.holdTypeCategory.toLowerCase().replace(/ +/g, '')
                        }
                        sx={{ fontSize: '1.714rem', lineHeight: '1.44rem' }}
                    >
                        {item.holdTypeInfo.title}
                    </Typography>
                    <Typography variant="caption" component="p" sx={{ lineHeight: '1.143rem' }}>
                        {item.holdTypeInfo.description}
                    </Typography>
                </Box> */}
                {/* <Divider sx={{ margin: '0!important' }} /> */}
                {/* <Grid container px={2} py={3} sx={{ m: '0!important' }}>
                    <Grid item xs={12}>
                        <Autocomplete
                            size="small"
                            onChange={(event, value) => handleChange(value, item)}
                            onInputChange={handleInputChange}
                            disableClearable
                            disabled={(isSubmitted() || isDraftCheck()) && !editMode}
                            value={
                                item.receiverAcceptance.title
                                    ? getResponseObject(item.receiverAcceptance.value)
                                    : getResponseObject(item.receiverAcceptance)
                            }
                            options={responses}
                            autoHighlight
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // placeholder={item.taskType || item.tag ? '' : 'Choose'}
                                    label="My Response*"
                                    placeholder="Select your acceptance"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                </Grid> */}
            </Stack>
        </>
    );
}

export default BookingDatesItem;
